<template>
  <LargeLayout>
    <h1 class="text-success text-center mb-3">
      {{ $t("contact.Contact List") }}
    </h1>
    <div v-for="item in contacts" :key="item._id" class="mb-3">
      <div class="card-header bg-success h4 text-white">{{ item.title }}</div>
      <div class="card-body border border-3 border-success container">
        <div class="row">
          <div class="col-12 mb-3">
            <div class="row">
              <span class="col-3">{{ $t("contact.userName") }}</span>
              <span class="col-9">{{ item.name }}</span>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <span class="col-3">{{ $t("contact.email") }}</span>
              <span class="col-9">{{ item.email }}</span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="row">
              <span class="col-3 mb-3">{{ $t("contact.category") }}</span>
              <span class="col-9">{{ item.category }}</span>
            </div>
          </div>
          <div class="col-12 mb-3">
            <div class="row">
              <span class="col-3">{{ $t("contact.create date") }}</span>
              <span class="col-9">{{ item.createdAt }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <p class="col-3">{{ $t("contact.message") }}</p>
              <p class="col-9">{{ item.message }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <AnswerForm
            @answer-success="answerSuccess"
            :name="item.name"
            :email="item.email"
            :id="item._id"
            :title="item.title"
          />
        </div>
      </div>
    </div>
    <b-pagination
      align="center"
      v-if="contacts.length > 0"
      v-model="page"
      :total-rows="count"
      :per-page="size"
      @change="handlePageChange"
      aria-controls="history-list"
    ></b-pagination>
  </LargeLayout>
</template>

<script>
import axiosConfig from "@/libs/axiosConfig";
import AnswerForm from "./components/AnswerForm.vue";
import LargeLayout from "@/layout/main/LargeLayout";
export default {
  components: { LargeLayout, AnswerForm },
  data() {
    return { contacts: [], page: 1, size: 5, count: 0 };
  },
  mounted() {
    axiosConfig
      .get("/contact?page=" + this.page + "&size=" + this.size)
      .then((response) => {
        this.contacts = response.data.contacts;
      })
      .catch((error) => console.log(error));
  },
  methods: {
    answerSuccess(id) {
      const index = this.contacts.findIndex((item) => item._id === id);
      this.contacts.splice(index, 1);
    },
    handlePageChange(value) {
      this.page = value;
      axiosConfig
        .get("/contact?page=" + this.page + "&size=" + this.size)
        .then((response) => {
          this.contacts = response.data.contacts;
          this.count = response.data.totalItems;
        });
    },
  },
};
</script>

<style>
.toggleAnswer {
  max-height: 35px;
  max-width: 100px;
}
</style>
