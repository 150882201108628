<template>
  <div class="col-12">
    <div class="row">
      <b-button
        class="col-3 bg-success border-0 font-weight-bold mt-3 toggleAnswer"
        @click="toggleAnswer = !toggleAnswer"
        >{{ $t("contact.Answer") }}</b-button
      >
      <b-form
        class="col-9"
        :class="{ 'd-none': toggleAnswer }"
        @submit.prevent="onSubmit"
      >
        <b-form-textarea
          id="textarea"
          v-model="text"
          :placeholder="$t('contact.Answer placeholder')"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-button type="Submit">{{ $t("contact.Submit") }}</b-button>
      </b-form>
    </div>
  </div>
</template>
<script>
import axiosIns from "../../../libs/axiosConfig";
export default {
  data() {
    return { text: "", toggleAnswer: true };
  },
  props: ["title", "email", "id", "name"],
  methods: {
    onSubmit() {
      axiosIns
        .post("/contact/answer/" + this.id, {
          name: this.name,
          title: this.title,
          email: this.email,
          message: this.text,
        })
        .then((response) => {
          this.$emit("answer-success", this.id);
          alert(response.data.message);
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style></style>
